.input {
    border: 1px solid #D9DCE9;
    height: 48px;
    width: 100%;
    outline: none;
    padding: 0 17px;
    margin-top: 8px;
    margin-bottom: 8px;
    border-radius: 5px;
}
.input.inValid {
  border-color: red;
}
