.linkItem {
    display: block;
    text-align: center;
    text-decoration: none;
    color: var(--text-color);
}

.linkItem.inline {
  display: inline-block;
}

.linkItem.white {
  color: #fff;
}

.linkItem.blue {
  color: #589abe;
}

.linkItem:focus,
.linkItem:hover {
    color: var(--text-hover-color);
    -webkit-transition: color 0.3s ease-out;
    -moz-transition: color 0.3s ease-out;
    -o-transition: color 0.3s ease-out;
    transition: color 0.3s ease-out;
}

.linkItemImage {
    margin-right: 5px;
}

.linkItem.vkBtn{
  background: #0077FF;
  color: var(--color-white);
}
.linkItem.vkOk{
  background: #F7931E;
  color: var(--color-white);
}
.linkItem.vkFacebook{
  background: #157DC3;
  color: var(--color-white);
}
.linkItem.vkApple{
  background: var(--background-black);
  color: var(--color-white);
}
.linkItem.vkGoogle{
  background: var(--background);
  color: var(--color-grey);
  border: 1px solid #D9D9D9
}
.linkItem.corpMainStyle{
  width: 100%;
  padding: 10px 10px 10px 15px;
  column-gap: 32px;
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 7px;
  margin-bottom: 7px;
  transition: opacity 0.3s linear;
}

.linkItem.corpMainStyle:hover,
.linkItem.corpMainStyle:focus {
  opacity: 0.8;
}

.linkItem.outButton {
  padding: 8px 24px;
  line-height: 20px;
  border-radius: 4px;
  background: #589abe;
  font-size: 14px;
  font-weight: 400;
  color: #fff;
  transition: background-color 0.3s linear;

  &:hover, &:focus {
    background: #0d6797;
  }
}

.linkItem.outButton div {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  flex-wrap: nowrap;
}

.linkItem.outButton div span {
  width: max-content;
}

.linkItem.outButton div .linkItemImage {
  margin: 3px 0 0;
}

.linkItem.headerIcon {
  line-height: 0;
  position: relative;
}
.linkItem.headerIcon svg {
  transform: scale(2);
  transform-origin: left center;
}

.linkItem.headerIcon svg path {
  transition: fill 0.3s linear;
}
.linkItem.headerIcon:hover svg path {
  fill: #ff2d38;
}
