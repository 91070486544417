.admin-panel {
  position: fixed;
  top: 100px;
  left: 7px;
}

.btn {
  position: relative;
  cursor: pointer;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  transition: all 0.2s linear 0s;
  display: block;
  box-shadow: rgba(52, 74, 153, 0.1) 0px 0px 7px 0px;
  margin-bottom: 7px;
}

.btn.red {
  background: rgb(255, 115, 123);
}

.btn.grey {
  background: rgb(189, 189, 189);
}

.btn.blue {
  background: rgb(146, 235, 239);
}

.btn.yellow {
  background: rgb(255, 225, 45);
}

.btn.incard {
  position: absolute;
  top: 95px;
  right: 8px;
}

.btn.incard-two {
  position: absolute;
  top: 138px;
  right: 8px;
}

.btn.edit::after {
  content: "";
  position: absolute;
  inset: 9px;
  background: url(https://cdn-icons-png.flaticon.com/256/2740/2740678.png) center center / contain transparent;
}

.btn.match::after {
  content: "";
  position: absolute;
  inset: 9px;
  background: url(https://cdn-icons-png.flaticon.com/128/2783/2783606.png) center center / contain transparent;
}

.btn.gpt::after {
  content: "";
  position: absolute;
  inset: 9px;
  background: url(https://cdn-icons-png.flaticon.com/512/11865/11865326.png) center center / contain transparent;
}
