@media screen and (max-width: 1435px) {
  .button {
    padding: 8px 18px;
  }
}

.corpMainStyle {
  width: 100%;
  padding: 10px 10px 10px 15px;
  column-gap: 32px;
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  margin-top: 7px;
  margin-bottom: 7px;
  transition: opacity 0.3s linear;
}

.corpMainStyle.centered {
  justify-content: center;
}

.corpMainStyle:hover,
.corpMainStyle:focus {
  opacity: 0.8;
}

.enterBtn {
  background-color: #f4f5f9;
  width: 100%;
  height: 55px;
  line-height: 55px;
  border-radius: 5px;
  font-size: 16px;
  color: #4f4f4f;
  transition: all 0.3s linear;
}

.enterBtn:hover,
.enterBtn:focus {
  background-color: #e3e3e5;
}
