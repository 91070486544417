.buttonDrop {
  background: transparent;
  font-weight: 500;
  width: 188px;
  line-height: 50px;
  font-size: 16px;
  color: #3b4449;
}

.arrow {
  border: solid #ff2f3c;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  margin-left: 10px;
}

.up {
  transform: rotate(225deg);
  transition: transform 0.2s linear;
}

.down {
  transform: rotate(45deg);
  transition: transform 0.2s linear;
}

.dropdown .arrow {
  position: relative;
  bottom: 2px;
}

.dropdown .up {
  transform: rotate(225deg);
  transition: all 0.2s linear;
  bottom: -5px;
}

.dropdown .up::after {
  content: '';
  border: solid #ff2f3c;
  border-width: 2px 0 0 2px;
  display: inline-block;
  padding: 3px;
  position: absolute;
  top: 100%;
  left: 100%;
}

.dropdown .down {
  transform: rotate(45deg);
  transition: transform 0.2s linear;
}

/*.arrowUp svg {*/
/*    rotate: 90deg;*/
/*    filter: brightness(0) saturate(100%) invert(13%) sepia(10%) saturate(5813%) hue-rotate(196deg) brightness(97%) contrast(93%);*/
/*}*/

/*.arrowDown svg {*/
/*    rotate: 270deg;*/
/*    filter: brightness(0) saturate(100%) invert(13%) sepia(10%) saturate(5813%) hue-rotate(196deg) brightness(97%) contrast(93%);*/
/*}*/
