.listNav {
  font-weight: 500;
  list-style-type: none;
  display: flex;
  margin: 0 6px 0 34px;
}

.listNav li {
  margin-right: 25px;
  white-space: nowrap;
}

.itemCity {
  margin-right: 42px;
  margin-left: 50px;
}

.itemCity a {
  width: 70px;
}

.itemCity a div {
  display: flex;
  font-weight: 500;
  align-items: center;
}

.formSearchInput {
  display: flex;
  align-items: center;
}

.headerButton {
  background: transparent;
  font-weight: 500;
  border: 1px solid #4a4d53;
  border-radius: 8px;
  padding: 12px 22px;
  margin-top: 5px;
}

.headerButton:hover,
.headerButton:focus {
  color: var(--text-hover-color);

  -webkit-transition: color 0.3s ease-out;
  -moz-transition: color 0.3s ease-out;
  -o-transition: color 0.3s ease-out;
  transition: color 0.3s ease-out;
}

.searchInput {
  border-bottom: 1px solid #d9dce9;
  border-top: 1px solid #d9dce9;
  border-left: 1px solid #d9dce9;
  border-radius: 4px 0 0 4px;
  height: 48px;
  width: 508px;
  outline: none;
  padding: 0 17px;
}

.menu {
  display: flex;
  position: fixed;
  top: 93px;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  background: var(--background);
  height: -webkit-fill-available;
  overflow-y: auto;
  overflow-x: hidden;
}

.menuButtons {
  padding: 20px 0;
}

.headerMenu {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 24px 40px 0 24px;
}

.headerMenu h1 {
  font-size: 42px;
  font-weight: 700;
}

.menuCategory {
  display: flex;
  flex-flow: row wrap;
  width: calc(100% + 16px);
}

.menuRight {
  background: var(--background);
  width: 100%;
}

.headerMenu button {
  background: #ebeff5;
  width: 38px;
  height: 38px;
  border-radius: 50%;
}

.headerMenu svg {
  filter: invert(30%) sepia(15%) saturate(225%) hue-rotate(182deg) brightness(94%) contrast(94%);
  scale: 0.8;
}

@media screen and (max-width: 1683px) {
  .searchInput {
    width: 448px;
  }
}

@media screen and (max-width: 1619px) {
  .searchInput {
    width: 316px;
  }
}

@media screen and (max-width: 1508px) {
  .searchInput {
    width: 252px;
  }
}

@media screen and (max-width: 1435px) {
  .itemCity {
    margin-right: 18px;
    margin-left: 22px;
  }

  .listNav {
    margin: 0 0 0 22px;
  }

  .listNav li {
    margin-right: 18px;
  }

  .searchInput {
    height: 40px;
  }
}
