.description {
    padding-top: 10px;
    display: flex;
    align-items: center;
    column-gap: 15px;
}

.registerBtn{
    font-size: 16px;
    color: #1379D4;
    background: transparent;
}
